.starting {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerRow {
  width: 80%;
  display: flex;
}

.pollHeader {
  margin: 40px 0 20px 10px;
  font-size: 40px;
  opacity: 0.7;
}
