.wrapper {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.section {
  width: 60%;
  padding: 10px;
  margin: 5px;
  text-align: left;
}

.row {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.imageRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageDetails {
  width: 30%;
}

.imagePreview {
  width: 100%;
  object-fit: contain;
  cursor: pointer;
}

.caption {
  margin-top: 20px;
  margin-bottom: 10px;
  display: block;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
}

.buttonRow {
  flex-direction: row;
  justify-content: center;
}
