.starting {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.table {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.pollSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.actionRow {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-top: -20px;
}

.createButton {
  font-size: 0.8em;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  margin: auto 0;
}

.createButton:hover {
  opacity: 1;
}

.headerRow {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 50px;
}

.toggleGroup {
  margin-top: 10px;
  width: 80%;
}

.pollHeader {
  margin: 40px 0 20px 10px;
  font-size: 40px;
  opacity: 0.7;
}

.paginationRow {
  margin: 10px 0 50px;
}

.filtersSection {
  display: flex;
}
