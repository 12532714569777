.centerRow {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageDetails {
  width: 50%;
  display: flex;
  margin-bottom: 20px;
}

.label {
  width: 50%;
  cursor: pointer;
  margin-right: 10px;
}

.imageDetails p {
  font-size: 13px;
  margin-top: 2px;
}

.imagePreview {
  width: 100%;
  object-fit: contain;
}

.caption {
  width: 50%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
}

.caption p {
  margin: 0 0 5px 0;
}

.detailsTable td,
th {
  text-align: right;
}

.detailsTable {
  margin-bottom: 30px;
}
