.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper {
  width: 80%;
}

.centerRow {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.imagePiece {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

.imagePiece:hover {
  opacity: 0.8;
}
