.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 24;
  padding-left: 15px;
  max-width: 80%;
  max-height: 90%;
  overflow-y: auto;
}

.centerRow {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.pollColumns {
  display: flex;
}

.pollColumn {
  margin-right: 50px;
}

.controlLabel > span:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
}
