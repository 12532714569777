.wrapper {
  margin-left: 120px;
  margin-top: 20px;
}

.table {
  width: 100%;
}

.newQuestionRow {
  font-size: 15px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2 ease-in-out;
}

.newQuestionRow:hover {
  opacity: 1;
}
