.box {
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  background-color: "gray";
  border: "2px solid #000";
  box-shadow: 24;
  padding-left: "15px";
}

.centerRow {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.imagePiece {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

.imagePiece:hover {
  opacity: 0.8;
}
