.wrapper {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.section {
  width: 60%;
  padding: 10px;
  margin: 5px;
  text-align: left;
}

.row {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.imageRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.imageDetails {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.imageDetails p {
  margin-right: 10px;
}

.imagePreview {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.caption {
  margin-top: 20px;
  margin-bottom: 10px;
  display: block;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
}
