.section {
  width: 80%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 10px;
  margin: 5px;
  text-align: left;
}

.listHeader {
  display: flex;
  justify-content: space-between;
}

.listHeader h4 {
  margin-block-start: 0;
  margin-block-end: 10px;
}

.listItems {
  margin-left: 10px;
}

.listItems div {
  margin-bottom: 5px;
}
