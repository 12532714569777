.section {
  width: 80%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 10px;
  margin: 5px;
  text-align: left;
}

.wrapper {
  display: flex;
}

.imageWrapper {
  width: 166px;
  height: 100px;
  margin-right: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.contentRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.contentColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  font-size: 15px;
  font-weight: 600;
}

.headerID {
  font-weight: 400;
}

.description {
  margin-top: 5px;
  font-size: 13px;
}

.lastColumn {
  text-align: right;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.buttons {
  font-size: 0.8em;
}

.buttons span {
  margin-right: 20px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
}

.buttons span:hover {
  opacity: 1;
}

.readButton,
.editButton {
  font-size: 0.8em;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
}

.readButton:hover {
  opacity: 1;
}

.readButton a {
  text-decoration: none;
  color: black;
}

.editButton {
  font-size: 0.85em;
  cursor: pointer;
}

.editButton:hover {
  opacity: 1;
}
