.starting {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerRow {
  width: 80%;
}

.starting h1 {
  font-size: 4rem;
  opacity: 0.7;
}
