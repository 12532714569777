.wrapper {
  margin-top: 60px;
}

.table {
  width: 100%;
  font-size: 15px;
}

.editButton {
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2 ease-in-out;
}

.editButton:hover {
  opacity: 1;
}

.correctAnswer {
  color: rgb(0, 177, 31);
}
