.tableRow {
  font-size: 15px;
}

.pointerColumn {
  font-size: 0.9em;
  text-align: right;
}

.pointerElement {
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2 ease-in-out;
}

.pointerElement:hover {
  opacity: 1;
}

.answerRow {
  padding-left: 30px;
}
