.table {
  width: 100%;
}

.tableRow {
  font-size: 15px;
}

.pointerColumn {
  cursor: pointer;
}

.correctAnswer {
  color: rgb(0, 177, 31);
}

.pointerElement {
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2 ease-in-out;
}

.pointerElement:hover {
  opacity: 1;
}

.newAnswerRow {
  font-size: 15px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2 ease-in-out;
}

.newAnswerRow:hover {
  opacity: 1;
}

.contentColumn {
  display: flex;
  align-items: center;
}
