.wrapper {
  margin-left: 120px;
}

.row {
  margin-top: 10px;
}

.caption {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
}

.content {
  font-size: 15px;
}

.sectionHeader {
  cursor: pointer;
}
